import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-local-login',
  templateUrl: './local-login.component.html',
  styleUrls: ['./local-login.component.scss']
})
export class LocalLoginComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  onLoggedIn(event: any): void {
    this.router.navigate(['']);
  }
  onLoggedFail(event: any): void {
    alert('login failed');
  }
}
