import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss']
})
export class SsoLoginComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.location.href = `/authentication/api/login/oauth2`;
  }
}
