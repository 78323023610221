import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  serviceUrl = `${window.location.origin}/user`;

  constructor(private http: HttpClient) {}

  public changePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<any> {
    return this.http.patch(this.serviceUrl + '/api/user/password/change', {
      oldPassword,
      newPassword
    });
  }
}
