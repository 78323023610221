import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@arts2/ngx-authentication';
import { Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { UserService } from '../user.service';
import { MatFormFieldAppearance } from '@angular/material/form-field';

const APPS = [
  { label: 'Test Design', url: 'test-design' },
  { label: 'Dashboard', url: 'dashboard' },
  { label: 'Admin', url: 'admin' },
  { label: 'Aircraft', url: 'aircraft' },
  { label: 'Test Management', url: 'test-management' },
  { label: 'ASI', url: 'asi' },
  { label: 'GTR-ITR', url: 'gtr-itr' },
  { label: 'Drawings', url: 'drawings' },
  { label: 'User Requests', url: 'user-requests' },
  { label: 'Ground Test Report', url: 'ground-test-report' },
  { label: 'GTR Coverage', url: 'gtr-coverage' },
  { label: 'Tools & Params', url: 'tools-parameters' }
];

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @Input()
  oldPassword = 'oldPassword';
  @Input()
  password = 'password';
  @Input()
  confirmPassword = 'confirmPassword';

  @Input()
  showRevealPasswordIcon = true;

  @Input()
  appearance?: MatFormFieldAppearance = 'fill';

  hidePasswordValue = true;
  hideConfirmPasswordValue = true;

  passwordChangeForm: UntypedFormGroup;

  public readonly apps = APPS;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    // this.toTestDesign();
    const formOptions: any = {
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      oldPassword: ['', Validators.required]
    };

    this.passwordChangeForm = this.fb.group(formOptions);
  }

  toApp(url: string): void {
    window.document.location.href = `/${url}`;
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['login']);
    });
  }

  changePassword(): void {
    const { value, valid } = this.passwordChangeForm;
    this.userService
      .changePassword(value.oldPassword, value.password)
      .subscribe(
        res => {
          this.logout();
        },
        err => console.log('HTTP Error', err),
        () => console.log('HTTP request completed.')
      );
  }
}
