<div class="default-container">

  <mat-card appearance="outlined" class="intro">
    <mat-icon aria-hidden="false">info</mat-icon>
    <div class="text">
      <p>You have reached temporary (release 7.0) GTSI Home application.</p>
      <div class="italic">
        <p>In future releases this view will not be visible, instead SSO login will be requested directly.</p>
        <p>Please note that each strategy uses a separate set of users: the login you will use will have roles and rights
          depending on the strategy selected.</p>
      </div>
    </div>
  </mat-card>

  <div class="actions">
    <button mat-raised-button color="primary" (click)="toSSOLogin()">Login using SSO strategy</button>
    <button mat-raised-button color="primary" (click)="toLocalLogin()">Login using GTSI internal strategy</button>
  </div>

</div>
