<div class="home-container">

  <mat-card appearance="outlined" class="intro">
    
    <mat-icon aria-hidden="false">info</mat-icon>
    <div class="text">
      <p>You have reached temporary (release 7.0) GTSI Home application.</p>
      <p class="italic">In future releases reaching this view will trigger automatic navigation to one of GTSI
        applications.</p>
    </div>
  </mat-card>


  <div class="applications">
    <button *ngFor="let app of apps"
      mat-raised-button
      color="primary"
      (click)="toApp(app.url)">
      {{ app.label }}
    </button>
  </div>

  <div class="logout">
    <button mat-raised-button color="warn" class="revokeButton" (click)="logout()">Logout</button>
  </div>

  <mat-card appearance="outlined" class="password-change">
    <h3>
      <mat-icon aria-hidden="false">person</mat-icon>
      Password change
    </h3>
    <form [formGroup]="passwordChangeForm" (submit)="changePassword()">
      <!-- old password -->
      <mat-form-field [appearance]="appearance">
        <mat-label >Old Password</mat-label>
        <input
          [type]="hidePasswordValue ? 'password' : 'text'"
          name="oldPassword"
          formControlName="oldPassword"
          matInput
          placeholder="Old Password"
        />
        <mat-icon matSuffix (click)="hidePasswordValue = !hidePasswordValue" *ngIf="showRevealPasswordIcon">{{
          hidePasswordValue ? 'visibility' : 'visibility_off'
          }}</mat-icon>
        <mat-error
          *ngIf="passwordChangeForm.controls['oldPassword'].hasError('required')">Old password required
        </mat-error>
      </mat-form-field>
      <!-- new password -->
      <mat-form-field [appearance]="appearance">
        <mat-label >New Password</mat-label>
        <input
          [type]="hidePasswordValue ? 'password' : 'text'"
          name="password"
          formControlName="password"
          matInput
          placeholder="New Password"
        />
        <mat-icon matSuffix (click)="hidePasswordValue = !hidePasswordValue" *ngIf="showRevealPasswordIcon">{{
          hidePasswordValue ? 'visibility' : 'visibility_off'
          }}</mat-icon>
        <mat-error
          *ngIf="passwordChangeForm.controls['password'].hasError('required')">New password required
        </mat-error>
      </mat-form-field>
      <mat-form-field [appearance]="appearance">
        <mat-label >Confirm Password</mat-label>
        <input
          name="confirmPassword"
          [type]="hideConfirmPasswordValue ? 'password' : 'text'"
          name="confirmPassword"
          formControlName="confirmPassword"
          matInput
          placeholder="Confirm Password"
        />
        <mat-icon *ngIf="showRevealPasswordIcon" matSuffix
                  (click)="hideConfirmPasswordValue = !hideConfirmPasswordValue">{{
          hideConfirmPasswordValue ? 'visibility' : 'visibility_off'
          }}</mat-icon>
        <mat-error *ngIf="passwordChangeForm.controls['confirmPassword'].hasError('required')">
          Confirm password required
        </mat-error>
        <mat-error *ngIf="passwordChangeForm.controls['confirmPassword'].errors?.MatchPassword">
          Passwords don't match
        </mat-error>
      </mat-form-field>
      <button
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="passwordChangeForm.invalid || passwordChangeForm.disabled"
      >
        Submit
      </button>
    </form>
  </mat-card>
</div>
