import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import {
  Arts2IsAuthenticatedGuard,
  Arts2IsNotAuthenticatedGuard
} from '@arts2/ngx-authentication';
import { DefaultComponent } from './default/default.component';
import { SsoLoginComponent } from './sso-login/sso-login.component';
import { LocalLoginComponent } from './local-login/local-login.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [Arts2IsAuthenticatedGuard]
  },
  {
    path: 'login',
    component: DefaultComponent,
    canActivate: [Arts2IsNotAuthenticatedGuard]
  },
  {
    path: 'sso-login',
    component: SsoLoginComponent,
    canActivate: [Arts2IsNotAuthenticatedGuard]
  },
  {
    path: 'local-login',
    component: LocalLoginComponent,
    canActivate: [Arts2IsNotAuthenticatedGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
