import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  Arts2AuthenticationInterceptor,
  Arts2IsAuthenticatedGuard,
  Arts2IsNotAuthenticatedGuard,
  Arts2LoginModule,
  Arts2PasswordChangeModule,
  Arts2PasswordLostModule
} from '@arts2/ngx-authentication';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { UserService } from './user.service';
import { SsoLoginComponent } from './sso-login/sso-login.component';
import { LocalLoginComponent } from './local-login/local-login.component';
import { DefaultComponent } from './default/default.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SsoLoginComponent,
    LocalLoginComponent,
    DefaultComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDialogModule,
    MatButtonModule,
    MatGridListModule,
    MatListModule,
    MatCardModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    Arts2LoginModule.forRoot({
      apiBaseUrl: `${window.location.origin}/authentication/api`
    }),
    Arts2PasswordChangeModule.forRoot({
      apiBaseUrl: `${window.location.origin}/authentication/api`
    }),
    Arts2PasswordLostModule.forRoot({
      apiBaseUrl: `${window.location.origin}/authentication/api`
    }),
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Arts2AuthenticationInterceptor,
      multi: true
    },
    Arts2IsAuthenticatedGuard,
    Arts2IsNotAuthenticatedGuard,
    UserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
