<div class="login-container">
  <mat-card appearance="outlined">
    <arts2-login
      loginField="username"
      loginProvider="local"
      (loginSuccess)="onLoggedIn($event)"
      (loginFail)="onLoggedFail($event)"
    ></arts2-login>
  </mat-card>
</div>
