import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  toSSOLogin(): void {
    window.document.location.href = `/sso-login`;
  }

  toLocalLogin(): void {
    window.document.location.href = `/local-login`;
  }
}
